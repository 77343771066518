@font-face {
  font-family: 'StabilGrotesk-Light';
  src: local('StabilGrotesk-Light'),
    url('assets/fonts/StabilGrotesk-Light.otf') format('truetype');
}

@font-face {
  font-family: 'StabilGrotesk-Medium';
  src: local('StabilGrotesk-Medium'),
    url('assets/fonts/StabilGrotesk-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'StabilGrotesk-Regular';
  src: local('StabilGrotesk-Regular'),
    url('assets/fonts/StabilGrotesk-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'),
    url('assets/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'),
    url('assets/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'),
    url('assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'),
    url('assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold'),
    url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'),
    url('assets/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'),
    url('assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'),
    url('assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url('assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'),
    url('assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'),
    url('assets/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Archivo';
  src: local('Archivo'), url('assets/fonts/Archivo.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url('assets/fonts/OpenSans.ttf') format('truetype');
}
