.ant-popover .ant-popover-content .ant-popover-inner {
  padding: 0;
}



.rsw-editor {
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: hidden;
}

.rsw-ce {
  flex: 1 0 auto;
  outline: none;
  padding: 0.5rem;
}

.rsw-cts {
  flex: 1 0 auto;
  outline: none;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.rsw-ce[contentEditable='true']:empty:not(:focus):before {
  content: attr(placeholder);
  color: grey;
}

.rsw-html {
  background: transparent;
  border: none;
  font-family: monospace, 'Courier New';
}

.rsw-separator {
  align-self: stretch;
  border-right: 1px solid #ddd;
  display: flex;
  margin: 0 3px;
}

.rsw-dd {
  box-sizing: border-box;
  outline: none;
}

.rsw-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  color: #222;
  font-size: 1em;
  height: 2em;
  outline: none;
  padding: 0;
  width: 2em;
}

.rsw-btn:hover {
  background: #eaeaea;
}

.rsw-btn[data-active='true'] {
  background: #e0e0e0;
}

.rsw-toolbar {
  align-items: center;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
}
